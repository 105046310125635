// React
import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// Templates
import { Solution } from '../components/templates/Solution'

// Theme
import { SolutionRole } from '../styles/SolutionThemes'

// Page
const SolutionPage = ({ navigate }) => {
  const image = useStaticQuery(graphql`
    query {
      artist: file(relativePath: { eq: "solution_artist.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
  <Solution role={SolutionRole.ARTIST} image={image.artist} navigate={navigate} />
)
}

SolutionPage.propTypes = {
  navigate: PropTypes.func,
}

export default SolutionPage

